import "./Button.scss";

import cx from "classnames";
import React, { PureComponent } from "react";

import { ButtonColor, ButtonIconPosition, ButtonSize } from "./button.enum";

interface ButtonDefaultProps {
    size?: ButtonSize;
    color?: ButtonColor;
    border?: boolean;
    disabled?: boolean;
    active?: boolean;
    iconPosition?: ButtonIconPosition;
    ariaLabel?: string;
}

interface ButtonProps extends ButtonDefaultProps {
    children?: any;
    icon?: React.ReactNode;
    onClick?: (e: React.MouseEvent) => void;
    className?: string;
}

class Button extends PureComponent<ButtonProps> {
    baseClassName = "e-button";

    btnRef: React.RefObject<HTMLButtonElement>;

    static defaultProps: ButtonDefaultProps = {
        size: ButtonSize.REGULAR,
        color: ButtonColor.PRIMARY,
        border: false,
        disabled: false,
        iconPosition: ButtonIconPosition.LEFT,
    };

    constructor(props) {
        super(props);
        this.btnRef = React.createRef();
    }

    componentDidUpdate() {
        if (this.props.active) {
            this.btnRef.current.focus();
        } else {
            this.btnRef.current.blur();
        }
    }

    getClassName(): string {
        const { size, color, icon, children, iconPosition, border } = this.props;
        const c = this.baseClassName;
        return cx(
            c,
            this.props.className,
            `${c}--size-${size}`,
            `${c}--color-${color}`,
            { [`${c}--only-icon`]: icon && !children },
            { [`${c}--icon-${iconPosition}`]: icon && children },
            { [`${c}--border`]: border },
        );
    }

    render(): React.ReactNode {
        const { iconPosition, children, icon, onClick, disabled, ariaLabel } = this.props;

        return (
            <button aria-label={ariaLabel} ref={this.btnRef} className={this.getClassName()} disabled={disabled} onClick={onClick}>
                {iconPosition === ButtonIconPosition.LEFT ? icon : ""}
                {children}
                {iconPosition === ButtonIconPosition.RIGHT ? icon : ""}
            </button>
        );
    }
}

export default Button;
