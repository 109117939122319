export enum MessageType {
    WARNING = "warning",
    SUCCESS = "success",
    INFO = "info",
    ERROR = "error",
}

export enum SocialNetwork {
    FACEBOOK = "facebook",
    INSTAGRAM = "instagram",
}
