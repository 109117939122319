import "./Alert.scss";

import cx from "classnames";
import React, { PureComponent } from "react";

import icons from "@/components/icons";

import { MessageType } from "../../enums";
import TypedMessage from "../TypedMessage";
import { AlertView } from "./alert.enum";

interface AlertDefaultProps {
    /** MessageType { WARNING = 'warning', SUCCESS = 'success', INFO    = 'info', ERROR   = 'error' } */
    type?: MessageType;
    /** AlertView { TOAST = 'toast', EMBEDDED = 'embedded', STRIPE = 'stripe' } */
    view?: AlertView;
}

interface AlertProps extends AlertDefaultProps {
    id?: string;
    onClose?: (id: string) => void;
    children: string | React.ReactElement;
    className?: string;
}

class Alert extends PureComponent<AlertProps> {
    baseClassName = "c-alert";

    static defaultProps: AlertDefaultProps = {
        type: MessageType.SUCCESS,
        view: AlertView.EMBEDDED,
    };

    onClose = (): void => this.props.onClose(this.props.id);

    getAlertClassName(): string {
        const c = this.baseClassName;
        return cx(c, this.props.className, `${c}--view-${this.props.view}`, `${c}--type-${this.props.type}`);
    }

    getAlertCloseClassName(): string {
        const c = `${this.baseClassName}__close`;
        return cx(c, `${c}--${this.props.type}`);
    }

    render(): React.ReactNode {
        return (
            <div className={this.getAlertClassName()}>
                <TypedMessage type={this.props.type as MessageType}>{this.props.children}</TypedMessage>

                {this.props.onClose && (
                    <i className={this.getAlertCloseClassName()} onClick={this.onClose}>
                        {icons.CLOSE}
                    </i>
                )}
            </div>
        );
    }
}

export default Alert;
